import React, { useState, useEffect, useCallback } from 'react'
import Input from '../../../../common/components/Input/Input'
import Button from '../../../../common/components/Button'
import { regex } from '../../../../utils/regex'
import { postRequestAsync, putRequestAsync, getRequestAsync } from '../../../../common/genericAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setSnackbar, setShowSpinner, setSelectedItem } from '../../../../redux/common'
import Dropdown from '../../../../common/components/Dropdown'
import { days } from '../../../../utils'

const BroadcastsForm = () => {
  const { search = '' } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => state.common.selectedItem)
  const [fields, setFields] = useState({
    title: '',
    description: '',
    timeToLive: '',
  })
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    timeToLive: '',
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (e) => {
    e.returnValue = 'Changes you made may not be saved.'
  }

  useEffect(() => {
    if (!(selectedItem && Object.keys(selectedItem).length) && search.includes('id')) {
      const getCategory = async () => {
        try {
          dispatch(setShowSpinner(true))
          const response = await getRequestAsync('broadcasts', search)
          dispatch(setSelectedItem(response.broadcasts[0]))
          dispatch(setShowSpinner(false))
        } catch (e) {
          console.log(e)
          dispatch(setShowSpinner(false))
        }
      }
      getCategory()
    }
  }, [selectedItem, search, dispatch])

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length) {
      setIsUpdate(true)
      setFields({
        title: selectedItem.title,
        description: selectedItem.description,
        timeToLive: '',
      })
    }
  }, [selectedItem])

  const handleOnChange = (e) => {
    e.persist()
    const trimmedValue = e.target.value.trim()
    setFields((fields) => ({ ...fields, [e.target.name]: trimmedValue ? e.target.value : trimmedValue }))
  }

  const handleOnDropdownChange = (selectedItem) => {
    setFields((fields) => ({ ...fields, timeToLive: selectedItem.value }))
  }

  const validate = useCallback(() => {
    let error = {},
      isValid = true
    Object.keys(fields).forEach((key) => {
      if (regex[key] && !regex[key].test(fields[key])) {
        error[key] = 'Invalid format'
        isValid = false
      }
      if (!fields[key]) {
        error[key] = 'Field is required'
        isValid = false
      }
    })
    setErrors(error)
    return isValid
  }, [fields])

  useEffect(() => {
    setIsDisabled(!validate())
  }, [validate])

  const handleOnSubmit = async (e) => {
    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key])
    })
    e.preventDefault()
    const functionCalls = {
      insert: postRequestAsync,
      update: putRequestAsync,
    }
    if (isUpdate) {
      formData.append('_id', selectedItem._id)
    }
    setIsSubmitted(true)
    if (validate()) {
      try {
        dispatch(setShowSpinner(true))
        const { message } = await functionCalls[isUpdate ? 'update' : 'insert']('broadcasts', formData)
        dispatch(setSnackbar({ type: 'success', value: message }))
        dispatch(setShowSpinner(false))
        navigate('/dashboard/broadcasts', { replace: true })
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'error', value: e.message }))
      }
    }
  }

  return (
    <div className='form-container'>
      <h2 className='ptb20'>{!isUpdate ? 'Add Broadcast' : 'Update Broadcast'}</h2>
      <form className='form' onSubmit={handleOnSubmit}>
        <Input
          label='Broadcast Title'
          id='title'
          name='title'
          placeholder='Broadcast Title'
          value={fields.title}
          handleOnChange={handleOnChange}
          error={errors.title}
          isSubmitted={isSubmitted}
        />
        <Input
          label='Description'
          id='description'
          name='description'
          placeholder='Description'
          inputType='textarea'
          value={fields.description}
          handleOnChange={handleOnChange}
          error={errors.description}
          isSubmitted={isSubmitted}
        />
        <Dropdown
          id='timeToLive'
          value={fields.timeToLive}
          placeholder='Select Validity'
          searchText='Search'
          filterBy='value'
          options={days}
          handleOnChange={(selectedItem) => {
            handleOnDropdownChange(selectedItem)
          }}
          classObject={{ container: 'mb10' }}
          isDisabled={!days.length}
        />
        <Button
          text={!isUpdate ? 'Add Broadcast' : 'Update Broadcast'}
          isDisabled={isDisabled}
          className='btn btn-primary mt20'
          type='submit'
        />
      </form>
    </div>
  )
}

export default BroadcastsForm
