import jwt_decode from 'jwt-decode'
import instance from '../apiInstance'

export const isLoggedIn = () => {
  if (localStorage.accessToken) {
    // Set auth token header auth
    const token = localStorage.accessToken
    instance.defaults.headers.common['Authorization'] = token
    // Decode token and get user info and exp
    const decoded = jwt_decode(token)
    // Set user and isAuthenticated
    // Check for expired token
    const currentTime = Date.now() / 1000 // to get in milliseconds
    if (decoded.exp < currentTime) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('role')
      return false
    } else {
      return true
    }
  }
  return false
}

instance.interceptors.request.use(
  config => {
    if(!config.url.includes('dashboard')) {
      return config
    }
    if(config.url.includes('dashboard') && isLoggedIn()) {
      return config
    } else {
      throw new Error()
    }
  },
  err => {
    return err
  }
)
