import { configureStore } from '@reduxjs/toolkit'
import commonReducer from './common'
import categoriesReducer from './categories'
import productsReducer from './products'

export const store = configureStore({
  reducer: {
    common: commonReducer,
    categoryDetails: categoriesReducer,
    productDetails: productsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})
