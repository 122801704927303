import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
  product: ""
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.products = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProducts, setProduct } = productsSlice.actions

export default productsSlice.reducer
