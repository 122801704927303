import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSpinner: false,
  snackbar: {
    type: '',
    value: '',
  },
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setShowSpinner: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.showSpinner = action.payload
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setShowSpinner, setSnackbar, setSelectedItem } = commonSlice.actions

export default commonSlice.reducer
