import React from 'react'
import './Dashboard.scss'
import SideNavigation from './SideNavigation'
// import DataTable from "./DataTable";
import { Routes, Route, useNavigate } from 'react-router-dom'
import { isLoggedIn } from '../../utils/authorize'
import Categories from './Categories'
import Subcategories from './Subcategories'
import Products from './Products'
import logoutIcon from '../../images/logout.svg'
import Button from '../../common/components/Button'
import Broadcasts from './Broadcasts/Broadcasts'
import { Navigate } from 'react-router-dom'

const Dashboard = () => {
  const navigate = useNavigate()

  const handleOnLogout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('role')
    navigate('/kmhm0110', { replace: true })
  }

  return isLoggedIn() ? (
    <div className='dashboard-container'>
      <Button
        text={<img className='add-sign' src={logoutIcon} alt='trash' />}
        className='fixed-logo logout'
        handleOnClick={handleOnLogout}
      />
      <SideNavigation />
      <Routes>
        <Route path='/categories/*' element={<Categories />} />
        <Route path='/subcategories/*' element={<Subcategories />} />
        <Route path='/products/*' element={<Products />} />
        <Route path='/broadcasts/*' element={<Broadcasts />} />
        <Route path='*' element={<Navigate to='/dashboard/categories' replace />} />
      </Routes>
      <div className='p20 text-center font-small'>
        &copy; {new Date().getFullYear()} Burhani Jewellers. All Rights Reserved.
      </div>
    </div>
  ) : (
    <Navigate to='/kmhm0110' />
  )
}

export default Dashboard
