import React, { useEffect, useRef } from "react";
import "./Snackbar.scss";

const Snackbar = props => {
  const {
    type,
    value,
    callback
  } = props;

  const snackbar = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    snackbar.current = setTimeout(() => {
      callback();
    }, 3000);

    return () => {
      clearTimeout(snackbar.current);
    };
  }, [callback]);

  return (
    <div id="snackbar" className={`${type}-alert`}>
      <img
        className="snackbar-icon"
        src={require(`../../../images/${type}.png`)}
        alt={type}
      />
      <span className="snackbar-message">{value}</span>
      <button className="snackbar-close cursor-pointer" onClick={callback}>
        &times;
      </button>
    </div>
  );
};

export default Snackbar;
