import React, { useRef } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import DataTable from '../DataTable/DataTable'
import ProductsForm from './productsForm'

const Products = () => {
  const headings = useRef({
    name: 'Product Name',
    categoryName: 'Category Name',
    subcategoryName: 'Subcategory Name',
    orderId: 'Order ID',
  })

  return (
    <React.Fragment>
      <Routes>
        <Route
          path='/view'
          element={
            <DataTable
              header='Products'
              apiName='products'
              headings={headings.current}
              route='/dashboard/products/form'
            />
          }
        />
        <Route path='/form' element={<ProductsForm />} />
        <Route path='*' element={<Navigate to='/dashboard/products/view' replace />} />
      </Routes>
    </React.Fragment>
  )
}

export default Products
