import React, { useEffect } from "react";
import "./AboutUs.scss";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="about-us-container">
      <h2 className="pt20">
        <i>
          "Jewelry is like the perfect spice – it compliments what’s already
          there"
        </i>{" "}
        - Diane Von Furstenberg
      </h2>
      <p>
        We know you dream of one statement piece jewellery each day and what we
        do here is to turn your dreams into reality. We are working each day
        with an aim to bring a smile to our customers’ face. What we do, we just
        try to enhance your beauty with our meticulously crafted jewelry.
      </p>
      <p>
        Everything that we do passes through strict quality controls before we
        hand deliver it to them. Talking about our history, we would like to
        take you a few decades back when <b>Mr. Kauserali Motiwala</b> started
        his journey of creating a brand, which is known as Burhani Jewellers.
        Mr. Kauserali is the senior partner at Burhani Jewellers, a seasoned
        jeweller with an eye for detail.
      </p>
      <p>
        <b>Mr. Hatim Motiwala</b>, son of Mr. Kauserali Motiwala wanted to take
        this business to the summit of success. After pursuing Diploma is
        Gemmology, Diploma in Diamond and Pearl Grading Lab course from reputed
        universities Gemmology Institute of America, Gemmology Institute of
        India and Indian Institute of Jewellery respectively, Mr. Hatim joined
        his father’s business to take it where it stands today.
      </p>
      <p>
        Both of these personalities sit at the helm of Burhani Jewellers and
        they have been working hard to fulfil the commitments made to their
        esteemed clients who hail from Middle East, East Africa, Far East,
        Europe and the US.
      </p>
      <p>
        Burhani Jewellers offer readymade stone studded gents buttons, gents and
        ladies rings. We are specialized in customizing each kind of jewellery
        that you wish to embrace. Visit our shop for more details.
      </p>
      <p>
        Along with Burhani Jewellers, both partners have started three more
        businesses under the names of Maimoon Creations, Classic Zone and S. M. B. 
        Collections with the doa and raza Mubarak of Aqa Maula.
      </p>
      <h3>Maimoon Creations</h3>
      <p>
        If you are a die-hard fan of fashion jewelry, made into silver and other
        semi-precious metals, we are the right destination for you. We procure
        the best pieces of fashion jewellery from different parts of the world
        and offer in affordable wholesale and retail prices. Visit our shop to
        see our complete collection.
      </p>
      <h3>Classic Zone</h3>
      <p>
        We all love fragrances, don’t we? We did out bit to offer some of the
        most exclusive and legitimate fragrances at your doorstep. We are
        specialized dealers of authentic Oudh and Mukhalat Attar and high
        quality original Bukhoor along with other varieties as well. Everything
        is handpicked from their mainland and brought here to quell your thirst
        for the right fragrance.
      </p>
      <h3>S. M. B Collections</h3>
      <p>
        By inscribing the holy scripts of Islam like Ayatul-Kursi, Asmaullah,
        Awliyaullah names, we create novelties carved out of original agate and
        other precious stones. Give us your requirement and we will provide what
        you need. Visit our shop for an exclusive look at few of our frames.
      </p>
    </section>
  );
};

export default AboutUs;
