import React, { useEffect, useRef, useCallback } from "react";
import "./Carousel.scss";
import logo from "../../../images/logo.png";

let startX, startY, startTime;
const threshold = 150; //required min distance traveled to be considered swipe
const restraint = 100; // maximum distance allowed at the same time in perpendicular direction
const allowedTime = 300;

const Carousel = props => {
  const { carouselItems = [] } = props;

  const references = useRef([]);
  const dots = useRef([]);
  const interval = useRef(null);

  const slideIndex = useRef(1);

  const navigate = n => {
    showSlides((slideIndex.current += n));
  };

  const currentSlide = index => {
    showSlides((slideIndex.current = index));
  };

  const handleTouchStart = e => {
    let touchobj = e.changedTouches[0];
    startX = touchobj.pageX;
    startY = touchobj.pageY;
    startTime = new Date().getTime();
  };

  const handleTouchEnd = e => {
    let touchobj = e.changedTouches[0];
    const distX = touchobj.pageX - startX;
    const distY = touchobj.pageY - startY;
    const elapsedTime = new Date().getTime() - startTime;
    if (elapsedTime <= allowedTime) {
      if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
        const swipedir = distX < 0 ? 1 : -1; // if dist traveled is negative, it indicates left swipe
        navigate(swipedir);
      }
    }
  };

  const showSlides = useCallback(
    index => {
      if (index > carouselItems.length) {
        slideIndex.current = 1;
      }
      if (index < 1) {
        slideIndex.current = carouselItems.length;
      }
      for (let i = 0; i < carouselItems.length; i++) {
        references.current[i].style.display = "none";
        dots.current[i].classList.remove("carousel-active");
      }

      references.current[slideIndex.current - 1].style.display = "flex";
      dots.current[slideIndex.current - 1].classList.add("carousel-active");
    },
    [carouselItems.length]
  );

  useEffect(() => {
    references.current[0].style.display = "flex";
    dots.current[0].classList.add("carousel-active");
    interval.current = setInterval(() => {
      showSlides((slideIndex.current += 1));
    }, 5000);
    return () => {
      clearInterval(interval.current);
    };
  }, [showSlides]);

  return (
    <React.Fragment>
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {carouselItems.map((item, index) => (
          <div
            key={index}
            className="carousel-item fade"
            ref={node => (references.current[index] = node)}
          >
            <img
              className="carousel-image"
              src={item.image}
              alt="carousel-item"
              onError={e => {
                e.target.src = logo;   
                e.target.style.margin = 'auto';
                e.target.style.width = '80%';
                e.target.style.minHeight = 'auto';
              }}
              onLoad={e => {
                e.target.style.background = 'none';
              }}
            />
          </div>
        ))}
        <button
          className="previous"
          onClick={() => {
            navigate(-1);
          }}
        >
          &#10094;
        </button>
        <button
          className="next"
          onClick={() => {
            navigate(1);
          }}
        >
          &#10095;
        </button>
      </div>

      <div className="dot-container">
        {carouselItems.map((item, index) => (
          <span
            key={index}
            className="dot"
            ref={node => (dots.current[index] = node)}
            onClick={() => {
              currentSlide(index + 1);
            }}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default Carousel;
