import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Collection.scss'
import { getRequestAsync } from '../../common/genericAPI'

import { useDispatch, useSelector } from 'react-redux'
import { setShowSpinner, setSnackbar } from '../../redux/common'
import { setCategories } from '../../redux/categories'
import logo from '../../images/logo.png'

const Collection = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const showSpinner = useSelector((state) => state.common.showSpinner)

  const [isMobileScreen, setIsMobileScreen] = useState(false)

  const categoryName = useSelector(
    (state) => state.categoryDetails.subcategories.length > 0 && state.categoryDetails.subcategories[0].categoryName
  )
  const subCategories = useSelector((state) => state.categoryDetails.subcategories)

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('resize', handleOnResize)
    const category = new URLSearchParams(location.search).get('categoryId')

    if (category) {
      const getCategories = async () => {
        dispatch(setShowSpinner(true))
        try {
          const response = await getRequestAsync('subcategories', location.search)
          dispatch(
            setCategories(
              response.subcategories.sort(
                (a, b) => (a.orderId || response.subcategories.length) - (b.orderId || response.subcategories.length)
              )
            )
          )
          dispatch(setShowSpinner(false))
        } catch (e) {
          console.log(e)
          dispatch(
            setSnackbar({
              type: 'error',
              value: e.message,
            })
          )
          dispatch(setCategories([]))
          dispatch(setShowSpinner(false))
        }
      }
      getCategories()
    } else {
      navigate('/', { replace: true })
    }
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [location.search, dispatch, navigate])

  const handleOnResize = () => {
    setIsMobileScreen(window.innerWidth < 767)
  }

  const handleOnCardClick = (id) => {
    navigate(`/products?id=${id}`)
  }

  return (
    !showSpinner &&
    (categoryName ? (
      <div className='category-container'>
        <header className='category-header mb20 text-center'>{categoryName}</header>
        <div
          className='category-list'
          style={{
            gridTemplateColumns: subCategories && subCategories.length === 1 && isMobileScreen ? 'auto' : '',
          }}
        >
          {subCategories && subCategories.length > 0 ? (
            subCategories.map((subCategory) => (
              <div
                className='card cursor-pointer'
                key={subCategory._id}
                onClick={() => {
                  handleOnCardClick(subCategory._id)
                }}
              >
                <img
                  className='card-image'
                  src={subCategory.images[0].image}
                  alt='category'
                  onError={(e) => {
                    e.target.src = logo
                    e.target.style.height = 'auto'
                    e.target.style.objectFit = 'contain'
                    e.target.style.margin = 'auto'
                  }}
                  onLoad={(e) => {
                    e.target.style.background = 'none'
                  }}
                />
                <div className='mt10 mb10'>{subCategory.subcategoryName}</div>
              </div>
            ))
          ) : (
            <h1 className='not-available'>Sorry, this category does not have collections yet.</h1>
          )}
        </div>
      </div>
    ) : (
      <h1 className='not-available'>Sorry, collection is not available.</h1>
    ))
  )
}

export default Collection
