import React, { useRef } from 'react'
import './SideNavigation.scss'
import { useNavigate } from 'react-router-dom'

const routes = [
  { name: 'Categories', path: '/dashboard/categories' },
  { name: 'Subcategories', path: '/dashboard/subcategories' },
  { name: 'Products', path: '/dashboard/products' },
  { name: 'Broadcasts', path: '/dashboard/broadcasts' },
]

const SideNavigation = () => {
  const navigate = useNavigate()
  const sideNavigationElement = useRef(null)

  const handleOnNavigationClick = (open) => {
    sideNavigationElement.current.style.width = open ? '250px' : '0px'
  }

  const handleOnNavigationItemClick = (route) => {
    navigate(`${route}`, { replace: true })
    handleOnNavigationClick()
  }

  return (
    <React.Fragment>
      <button
        className='side-hamburger-menu'
        onClick={() => {
          handleOnNavigationClick(true)
        }}
      >
        &#9776;
      </button>
      <div className='side-navigation-container' ref={sideNavigationElement}>
        <button
          className='close-button'
          onClick={() => {
            handleOnNavigationClick(false)
          }}
        >
          &times;
        </button>
        {routes.map((route) => (
          <button
            key={route.name}
            className='side-navigation-item'
            onClick={() => {
              handleOnNavigationItemClick(route.path)
            }}
          >
            {route.name}
          </button>
        ))}
      </div>
    </React.Fragment>
  )
}

export default SideNavigation
