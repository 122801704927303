import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subcategories: [],
  category: '',
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.subcategories = action.payload
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
  },
})

export const { setCategories, setCategory } = categoriesSlice.actions

export default categoriesSlice.reducer
