import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './Login.scss'
import Input from '../../common/components/Input/Input'
import logo from '../../images/logo.png'
import Button from '../../common/components/Button'
import { postRequestAsync, putRequestAsync } from '../../common/genericAPI'
import { useNavigate } from 'react-router-dom'
import { setShowSpinner, setSnackbar } from '../../redux/common'
import instance from '../../apiInstance'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [formFields, setFormFields] = useState({
    username: '',
    password: '',
    currentPassword: '',
    newPassword: '',
  })

  const [formErrors] = useState({
    username: '',
    password: '',
  })

  const [isDisabled, setIsDisabled] = useState(true)
  const [showOptions, setShowOptions] = useState({
    username: true,
    password: true,
  })

  useEffect(() => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('role')
  }, [])

  const handleOnChange = (e) => {
    e.persist()
    setFormFields((formFields) => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    let isValid = true
    Object.keys(formFields).forEach((key) => {
      if (showOptions[key]) {
        if (!formFields[key]) {
          isValid = false
        }
      }
    })
    setIsDisabled(!isValid)
  }, [formFields, showOptions])

  const handleOnForgotPasswordClick = () => {
    setFormFields({ username: '', currentPassword: '', newPassword: '' })
    setShowOptions({ username: true, currentPassword: true, newPassword: true })
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    try {
      dispatch(setShowSpinner(true))
      const response = await postRequestAsync('login', formFields)
      localStorage.setItem('accessToken', response.accessToken)
      localStorage.setItem('role', JSON.stringify(response.role))
      instance.defaults.headers.common['Authorization'] = response.accessToken
      dispatch(setShowSpinner(false))
      navigate('/dashboard', { replace: true })
    } catch (e) {
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: 'error', value: e.message }))
      console.log(e)
    }
  }

  const handleOnPasswordReset = async (e) => {
    e.preventDefault()
    try {
      dispatch(setShowSpinner(true))
      const { message } = await putRequestAsync('login', formFields)
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: 'success', value: message }))
      setFormFields((formFields) => ({ ...formFields, username: '', password: '' }))
      setShowOptions({ username: true, password: true })
    } catch (e) {
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: 'error', value: e.message }))
      console.log(e)
    }
  }

  return (
    <div className='login-container'>
      <form className='login-card' onSubmit={showOptions.password ? handleOnSubmit : handleOnPasswordReset}>
        <img className='login-logo' src={logo} alt='logo' />
        <h3 className='text-center mt20'>Sign in</h3>
        {showOptions.username && (
          <Input
            label='Username'
            id='username'
            name='username'
            placeholder='Enter username'
            value={formFields.username}
            handleOnChange={handleOnChange}
            error={formErrors.username}
          />
        )}
        {showOptions.password && (
          <Input
            label='Password'
            id='password'
            name='password'
            type='password'
            placeholder='Enter password'
            value={formFields.password}
            handleOnChange={handleOnChange}
            error={formErrors.password}
          />
        )}
        {showOptions.currentPassword && (
          <Input
            label='Current Password'
            id='currentPassword'
            name='currentPassword'
            type='password'
            placeholder='Enter current password'
            value={formFields.currentPassword}
            handleOnChange={handleOnChange}
            error={formErrors.currentPassword}
          />
        )}

        {showOptions.newPassword && (
          <Input
            label='New Password'
            id='newPassword'
            name='newPassword'
            type='password'
            placeholder='Type new password'
            value={formFields.newPassword}
            handleOnChange={handleOnChange}
          />
        )}
        {showOptions.password && (
          <Button
            className='btn-link text-left p0 mt10'
            type='button'
            handleOnClick={handleOnForgotPasswordClick}
            text='Forgot Password?'
          />
        )}
        <Button text='Sign In' className='btn btn-primary mt20' isDisabled={isDisabled} type='submit' />
      </form>
    </div>
  )
}

export default Login
