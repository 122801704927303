import axios from 'axios'

const configuration = {
  DEV: 'http://localhost:4000/api',
  INT: 'http://localhost:4000/api',
  PROD: '/api',
}

const instance = axios.create({
  baseURL: configuration[(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.replace(' ', '')) || 'INT'],
})

export default instance
