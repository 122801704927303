import React, { useEffect, useRef } from 'react'
import './Dropdown.scss'
import { useState } from 'react'

const Dropdown = props => {
  const {
    id = 'dropdown-component',
    value,
    placeholder,
    ValueComponent,
    searchText,
    filterBy,
    OptionsComponent,
    options,
    handleOnChange,
    classObject = {},
    isDisabled,
    children,
  } = props

  const [showDropdownOptions, setShowDropdownOptions] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState()

  const optionsComponent = useRef(null)
  const valueComponent = useRef(null)

  const handleOutsideClick = e => {
    const optionsElement = optionsComponent.current
    const valueElement = valueComponent.current
    if (
      optionsElement &&
      valueElement &&
      !valueElement.contains(e.target) &&
      !optionsElement.contains(e.target)
    ) {
      setShowDropdownOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    showDropdownOptions && valueComponent.current.focus()
  }, [showDropdownOptions])

  useEffect(() => {
    setDropdownOptions(options)
  }, [options])

  const handleOnFilter = e => {
    let filteredData
    filteredData = options.filter(option => {
      const optionValue = option[filterBy].toString().toLowerCase()
      const value = e.target.value.toLowerCase()
      return optionValue.includes(value)
    })
    setSearchValue(e.target.value)
    setDropdownOptions(e.target.value ? filteredData : options)
  }

  const handleOnOptionChange = (option, e) => {
    setShowDropdownOptions(false)
    handleOnChange(option, e)
    setDropdownOptions(options)
    setSearchValue('')
  }

  return (
    <div id={id} className={`dropdown-container ${classObject.container || ''}`}>
      <React.Fragment>
        {showDropdownOptions ? (
          <input
            id='search-options'
            className={`search-options ${classObject.optionsSearch || ''}`}
            type='text'
            value={searchValue}
            placeholder={searchText}
            autoComplete='off'
            onChange={handleOnFilter}
            ref={valueComponent}
            tabIndex={0}
          />
        ) : (
          <div
            tabIndex={0}
            className={`value 
                 ${classObject.valueContainer || ''}
                 ${isDisabled ? 'isDisabled' : ''}
                `}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                setShowDropdownOptions(true)
              }
            }}
            onClick={() => {
              setShowDropdownOptions(true)
            }}
          >
            {ValueComponent ? (
              <ValueComponent value={value} />
            ) : value ? (
              value
            ) : placeholder ? (
              <span className='placeholder'>{placeholder}</span>
            ) : (
              ''
            )}
          </div>
        )}
        {!isDisabled && <div className={`arrow-down ${classObject.arrowDown || ''}`} />}
        <div
          id='dropdown-options'
          className={`dropdown-options 
          ${classObject.optionsComponent || ''}
          ${showDropdownOptions ? 'show-dropdown' : 'hide-dropdown'}`}
          ref={optionsComponent}
        >
          {dropdownOptions &&
            dropdownOptions.length > 0 &&
            dropdownOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  id={`option-${index}`}
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      handleOnOptionChange(option, e)
                    }
                  }}
                  onClick={e => {
                    handleOnOptionChange(option, e)
                  }}
                  className={`dropdown-option ${classObject.option || ''}`}
                >
                  {OptionsComponent ? (
                    <OptionsComponent option={option} />
                  ) : (
                    <div>{option.value}</div>
                  )}
                </div>
              )
            })}
        </div>
        {!showDropdownOptions && children}
      </React.Fragment>
    </div>
  )
}

export default React.memo(Dropdown)
