import React, { useRef } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import DataTable from '../DataTable/DataTable'
import SubcategoryForm from './subcategoryForm'

const Subcategories = () => {
  const headings = useRef({
    subcategoryName: 'Subcategory Name',
    categoryName: 'Category Name',
    orderId: 'Order ID',
  })
  return (
    <React.Fragment>
      <Routes>
        <Route
          path='/view'
          element={
            <DataTable
              header='Subcategories'
              apiName='subcategories'
              headings={headings.current}
              route='/dashboard/subcategories/form'
            />
          }
        />
        <Route path='/form' element={<SubcategoryForm />} />
        <Route path='*' element={<Navigate to='/dashboard/subcategories/view' replace />} />
      </Routes>
    </React.Fragment>
  )
}

export default Subcategories
