import React, { useRef } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import DataTable from '../DataTable/DataTable'
import BroadcastForm from './BroadcastForm'

const Broadcasts = () => {
  const headings = useRef({
    title: 'Broadcast Title',
    timeToLive: 'Valid Till',
  })
  return (
    <React.Fragment>
      <Routes>
        <Route
          path='/view'
          element={
            <DataTable
              header='Broadcasts'
              apiName='broadcasts'
              headings={headings.current}
              route='/dashboard/broadcasts/form'
            />
          }
        />
        <Route path='/form' element={<BroadcastForm />} />
        <Route path='*' element={<Navigate to='/dashboard/broadcasts/view' replace />} />
      </Routes>
    </React.Fragment>
  )
}

export default Broadcasts
