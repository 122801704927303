import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="privacy-policy-container">
      <h1 className="text-center">PRIVACY POLICY</h1>
      <p>
        <b>Last updated: April 2020</b>
      </p>
      <p>
        This page paints Burhani Jewellers' policies, regarding the data
        collection, the disclosure of personal information the users provide us
        and what we do with the data. Your personal information is just to
        connect with you in a better way. By using this website, you agree to
        the collection and use of the information in accordance with this policy
        ("Privacy Policy", "Policy").
      </p>
      <h1>Collection of information and use</h1>
      <ol>
        <li>
          Personal information is information related to a visitor, or a
          combination of pieces of information that could reasonably allow him
          to be identified. We may even take some data required for the product
          you would inquire for.
        </li>
      </ol>
      <h1>Log data</h1>
      <ol>
        <li>
          Like many site operators, we collect information that your browser
          sends whenever you visit our website.
        </li>
        <li>
          This data may include information such as your computer’s Internet
          Protocol (IP) address, browser type, version, the pages you visit, the
          time you visit, the date you visit, the time you go through the
          website and other statistical information.
        </li>
        <li>
          In addition, we may use third party services to monitor and analyze
          our own website.
        </li>
      </ol>
      <h1>Communication</h1>
      <p>
        We may use the personal information provided by you to get in touch with
        you.
      </p>
      <h1>Cookies</h1>
      <p>
        Cookies are files with small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and stored on your computer's hard drive.
      </p>
      <p>
        Like many sites, we might be using "cookies" to collect information. You
        can instruct your browser to refuse all cookies or to indicate when a
        cookie is being sent.
      </p>
      <h1>Security</h1>
      <p>
        We care for your personal information and try our best to keep it
        secure. But remember, that no method of transmission over the internet,
        or method of electronic storage, is 100% secure. While we try our best
        to use commercially acceptable means to protect your information, we
        cannot guarantee its absolute security.
      </p>
      <p>
        We reserve the right to update or change our Privacy Policy at any time
        and you should check this Privacy Policy periodically. Your continued
        use of the Service after we post any modifications to the Privacy Policy
        on this page will constitute your acknowledgment of the modifications
        and your consent to abide and be bound by the modified Privacy Policy.{" "}
      </p>
      <p>
        If we make any changes to this Privacy Policy, we will notify you either
        through the email address you have provided us, or by placing a
        prominent notice on our website.
      </p>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about this Privacy Policy, please feel free to{" "}
        <Link to="/contact-us">
          <b>Contact Us</b>
        </Link>{" "}
        .
      </p>
    </section>
  );
};

export default PrivacyPolicy;
