import React, { useEffect } from "react";
import "./Modal.scss";

const Modal = props => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="modal-close" onClick={props.handleOnClose}>
          &times;
        </span>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
