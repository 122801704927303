import React from "react";
import "./Button.scss";

const Button = props => {
  const { className, handleOnClick, text, type, isDisabled } = props;
  return (
    <button className={className} onClick={handleOnClick} disabled={isDisabled} type={type}>
      {text}
    </button>
  );
};

export default React.memo(Button);
