import React, { useEffect } from "react";
import "./Spinner.scss";

const Spinner = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return <div className="loader" />;
};

export default Spinner;
