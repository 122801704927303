import React, { useState, useEffect, useRef, useCallback } from 'react'
import './subcategoryForm.scss'
import Input from '../../../../common/components/Input/Input'
import Button from '../../../../common/components/Button'
import { regex } from '../../../../utils/regex'
import { postRequestAsync, putRequestAsync, getRequestAsync } from '../../../../common/genericAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Dropdown from '../../../../common/components/Dropdown/Dropdown'
import { setSnackbar, setShowSpinner, setSelectedItem } from '../../../../redux/common'

const Form = () => {
  const { search = '' } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => state.common.selectedItem)

  const [categories, setCategories] = useState([])
  const [fields, setFields] = useState({
    subcategoryName: '',
    categoryName: '',
    orderId: '',
    image: '',
  })
  const [errors, setErrors] = useState({
    subcategoryName: '',
    categoryName: '',
    orderId: '',
    image: '',
  })
  const optionalFields = useRef({
    description: true,
    orderId: true,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const [image, setImage] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (e) => {
    e.returnValue = 'Changes you made may not be saved.'
  }

  useEffect(() => {
    if (!(selectedItem && Object.keys(selectedItem).length) && search.includes('id')) {
      const getProducts = async () => {
        try {
          dispatch(setShowSpinner(true))
          const response = await getRequestAsync('subcategories', search)
          dispatch(setSelectedItem(response.subcategories[0]))
          dispatch(setShowSpinner(false))
        } catch (e) {
          dispatch(setShowSpinner(false))
          console.log(e)
        }
      }
      getProducts()
    }
  }, [selectedItem, search, dispatch])

  useEffect(() => {
    const getCategories = async () => {
      try {
        dispatch(setShowSpinner(true))
        const response = await getRequestAsync('categories')
        setCategories(
          response.categories.map((category) => ({
            ...category,
            value: category.categoryName,
          }))
        )
        dispatch(setShowSpinner(false))
      } catch (e) {
        console.log(e)
        dispatch(setSnackbar({ type: 'error', value: e.message }))
        dispatch(setShowSpinner(false))
      }
    }
    getCategories()
  }, [dispatch])

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length) {
      setIsUpdate(true)
      setFields({
        subcategoryName: selectedItem.subcategoryName,
        categoryName: selectedItem.categoryName,
        orderId: selectedItem.orderId,
      })
      setImage(selectedItem.images[0].image)
    }
  }, [selectedItem])

  useEffect(() => {
    console.log(fields)
  }, [fields])

  useEffect(() => {
    if (fields.image) {
      const objectUrl = URL.createObjectURL(fields.image)
      setImage(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [fields.image])

  const handleOnChange = (e) => {
    e.persist()
    const trimmedValue = e.target.value.trim()
    setFields((fields) => ({ ...fields, [e.target.name]: trimmedValue ? e.target.value : trimmedValue }))
  }

  const handleOnDropdownChange = (selectedItem) => {
    setFields((fields) => ({ ...fields, categoryName: selectedItem.value }))
  }

  const handleOnImageChange = (e) => {
    e.persist()
    setFields((fields) => ({ ...fields, [e.target.name]: e.target.files[0] }))
  }

  const validate = useCallback(() => {
    let error = {},
      isValid = true
    Object.keys(fields).forEach((key) => {
      if (!optionalFields.current[key]) {
        if (regex[key] && !regex[key].test(fields[key])) {
          error[key] = 'Invalid format'
          isValid = false
        }
        if (!fields[key]) {
          error[key] = 'Field is required'
          isValid = false
        }
      }
    })
    setErrors(error)
    return isValid
  }, [fields])

  useEffect(() => {
    setIsDisabled(!validate())
  }, [validate])

  const handleOnSubmit = async (e) => {
    const functionCalls = {
      insert: postRequestAsync,
      update: putRequestAsync,
    }
    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key])
    })
    formData.append('categoryId', categories.find((category) => category.categoryName === fields.categoryName)._id)
    if (isUpdate) {
      formData.append('_id', selectedItem._id)
      formData.append('publicId', selectedItem.images[0].publicId)
    }
    e.preventDefault()
    setIsSubmitted(true)
    if (validate()) {
      try {
        dispatch(setShowSpinner(true))
        const { message } = await functionCalls[isUpdate ? 'update' : 'insert']('subcategories', formData)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'success', value: message }))
        navigate('/dashboard/subcategories', { replace: true })
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'error', value: e.message }))
      }
    }
  }

  return (
    <div className='form-container'>
      <h2 className='ptb20'>{!isUpdate ? 'Add Subcategory' : 'Update Subcategory'}</h2>
      <form className='form' onSubmit={handleOnSubmit}>
        <Dropdown
          id='category'
          value={fields.categoryName}
          placeholder='Select Category'
          searchText='Search Category'
          filterBy='value'
          options={categories}
          handleOnChange={handleOnDropdownChange}
          classObject={{ container: 'mb10' }}
          isDisabled={!categories.length}
        />
        <Input
          label='Subcategory Name'
          id='subcategoryName'
          name='subcategoryName'
          placeholder='Category Name'
          value={fields.subcategoryName}
          handleOnChange={handleOnChange}
          error={errors.subcategoryName}
          isSubmitted={isSubmitted}
        />
        <Input
          label='Order ID'
          id='orderId'
          name='orderId'
          placeholder='Order ID'
          value={fields.orderId}
          handleOnChange={handleOnChange}
          error={errors.orderId}
        />
        <input type='file' id='image' name='image' accept='image/*' onChange={handleOnImageChange} />
        {image && <img src={image} className='selected-image' alt='file-path' />}
        {isSubmitted && errors.image && <div className='error'>{errors.image}</div>}
        <Button
          text={!isUpdate ? 'Add Subcategory' : 'Update Subcategory'}
          isDisabled={isDisabled}
          className='btn btn-primary mt20'
          type='submit'
        />
      </form>
    </div>
  )
}

export default Form
