import React from "react";
import "./Input.scss";

const Input = props => {
  const {
    label,
    type = "text",
    inputType = "input",
    className = "",
    id,
    name,
    value,
    placeholder,
    handleOnChange,
    error,
    isSubmitted,
    isDisabled
  } = props;

  const InputType = inputType;

  return (
    <React.Fragment>
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <InputType
        id={id}
        name={name}
        className={`input ${className}`}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={isDisabled}
      />
      {isSubmitted && error && <div className="error">{error}</div>}
    </React.Fragment>
  );
};

export default Input;
