import instance from "../../apiInstance"

export const getRequestAsync = async (apiEndPoint, value) => {
  try {
    const response = await instance.get(`${apiEndPoint}${value ? `${value}` : ''}`)
    if (response.status === 200 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e.response) {
      const error = e.response.data.message
      throw new Error(error)
    }
    throw new Error('Network Error')
  }
}

export const postRequestAsync = async (apiEndPoint, body) => {
  try {
    const response = await instance.post(apiEndPoint, body)
    if (response.status === 200 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e.response) {
      const error = e.response.data.message
      throw new Error(error)
    }
    throw new Error('Network Error')
  }
}

export const putRequestAsync = async (apiEndPoint, body) => {
  try {
    const response = await instance.put(apiEndPoint, body)
    if (response.status === 200 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e.response) {
      const error = e.response.data.message
      throw new Error(error)
    }
    throw new Error('Network Error')
  }
}
