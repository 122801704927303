import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CategoryForm.scss'
import Input from '../../../../common/components/Input/Input'
import Button from '../../../../common/components/Button'
import { regex } from '../../../../utils/regex'
import { postRequestAsync, putRequestAsync, getRequestAsync } from '../../../../common/genericAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setSnackbar, setShowSpinner, setSelectedItem } from '../../../../redux/common'

const Form = () => {
  const { search = '' } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => state.common.selectedItem)
  const [fields, setFields] = useState({
    categoryName: '',
    description: '',
    orderId: '',
  })
  const [errors, setErrors] = useState({
    categoryName: '',
    description: '',
    orderId: '',
  })
  const optionalFields = useRef({
    description: true,
    orderId: true,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (e) => {
    e.returnValue = 'Changes you made may not be saved.'
  }

  useEffect(() => {
    if (!(selectedItem && Object.keys(selectedItem).length) && search.includes('id')) {
      const getCategory = async () => {
        try {
          dispatch(setShowSpinner(true))
          const response = await getRequestAsync('categories', search)
          dispatch(setSelectedItem(response.categories[0]))
          dispatch(setShowSpinner(false))
        } catch (e) {
          console.log(e)
          dispatch(setShowSpinner(false))
        }
      }
      getCategory()
    }
  }, [selectedItem, search, dispatch])

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length) {
      setIsUpdate(true)
      setFields({
        categoryName: selectedItem.categoryName,
        description: selectedItem.description,
        orderId: selectedItem.orderId,
      })
    }
  }, [selectedItem])

  const handleOnChange = (e) => {
    e.persist()
    const trimmedValue = e.target.value.trim()
    setFields((fields) => ({ ...fields, [e.target.name]: trimmedValue ? e.target.value : trimmedValue }))
  }

  const validate = useCallback(() => {
    let error = {},
      isValid = true
    Object.keys(fields).forEach((key) => {
      if (!optionalFields.current[key]) {
        if (regex[key] && !regex[key].test(fields[key])) {
          error[key] = 'Invalid format'
          isValid = false
        }
        if (!fields[key]) {
          error[key] = 'Field is required'
          isValid = false
        }
      }
    })
    setErrors(error)
    return isValid
  }, [fields])

  useEffect(() => {
    setIsDisabled(!validate())
  }, [validate])

  const handleOnSubmit = async (e) => {
    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key])
    })
    e.preventDefault()
    const functionCalls = {
      insert: postRequestAsync,
      update: putRequestAsync,
    }
    if (isUpdate) {
      formData.append('_id', selectedItem._id)
    }
    setIsSubmitted(true)
    if (validate()) {
      try {
        dispatch(setShowSpinner(true))
        const { message } = await functionCalls[isUpdate ? 'update' : 'insert']('categories', formData)
        navigate('/dashboard/categories', { replace: true })
        dispatch(setSnackbar({ type: 'success', value: message }))
        dispatch(setShowSpinner(false))
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'error', value: e.message }))
      }
    }
  }

  return (
    <div className='form-container'>
      <h2 className='ptb20'>{!isUpdate ? 'Add Category' : 'Update Category'}</h2>
      <form className='form' onSubmit={handleOnSubmit}>
        <Input
          label='Category Name'
          id='categoryName'
          name='categoryName'
          placeholder='Category Name'
          value={fields.categoryName}
          handleOnChange={handleOnChange}
          error={errors.categoryName}
          isSubmitted={isSubmitted}
        />
        <Input
          label='Description'
          id='description'
          name='description'
          placeholder='Description'
          inputType='textarea'
          value={fields.description}
          handleOnChange={handleOnChange}
          error={errors.description}
          isSubmitted={isSubmitted}
        />
        <Input
          label='Order ID'
          id='orderId'
          name='orderId'
          placeholder='Order ID'
          value={fields.orderId}
          handleOnChange={handleOnChange}
          error={errors.orderId}
        />
        <Button
          text={!isUpdate ? 'Add Category' : 'Update Category'}
          isDisabled={isDisabled}
          className='btn btn-primary mt20'
          type='submit'
        />
      </form>
    </div>
  )
}

export default Form
