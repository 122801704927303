import React from 'react'
import './Checkbox.scss'

const Checkbox = props => {
  const {
    name,
    checked,
    handleOnChange,
    children
  } = props
  return (
    <div className='checkbox-input font-size--medium'>
      <input
        name={name}
        type='checkbox'
        checked={checked}
        onChange={handleOnChange}
      />
      {children}
    </div>
  )
}

export default Checkbox