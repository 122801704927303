import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getRequestAsync } from '../../common/genericAPI'
import { useDispatch, useSelector } from 'react-redux'
import { setShowSpinner, setSnackbar } from '../../redux/common'
import Button from '../../common/components/Button/Button'
import Dropdown from '../../common/components/Dropdown'
import './Products.scss'
import Modal from '../../common/components/Modal'
import Input from '../../common/components/Input/Input'
import logo from '../../images/logo.png'
import { setProducts, setProduct } from '../../redux/products'
import Carousel from '../../common/components/Carousel/Carousel'

const sortByItems = [
  { label: 'Price - Low to High', sortBy: 'price', sortOrder: 'asc', value: 'Price - Low to High' },
  {
    label: 'Price - High to Low',
    sortBy: 'price',
    sortOrder: 'desc',
    value: 'Price - High to Low',
  },
  { label: 'Name - A-Z', sortBy: 'name', sortOrder: 'asc', value: 'Name - A-Z' },
  { label: 'Name - Z-A', sortBy: 'name', sortOrder: 'desc', value: 'Name - Z-A' },
]

const Products = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [displayProducts, setDisplayProducts] = useState(null)
  const [currentIndex, setCurrentIndex] = useState()
  const [showModal, setShowModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isMobileScreen, setIsMobileScreen] = useState(false)

  const showSpinner = useSelector((state) => state.common.showSpinner)
  const subCategoryName = useSelector(
    (state) => state.productDetails.products.length > 0 && state.productDetails.products[0].subcategoryName
  )
  const products = useSelector((state) => state.productDetails.products)

  const [sortByValue, setSortByValue] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('resize', handleOnResize)
    const searchBy = new URLSearchParams(location.search).get('id')

    if (searchBy) {
      const subcategoryId = location.search.replace('id=', 'subcategoryId=')
      const getProducts = async () => {
        try {
          dispatch(setShowSpinner(true))
          const products = await getRequestAsync('products', subcategoryId)
          dispatch(setProducts(products.products))
          setDisplayProducts(sort(products.products, { sortBy: 'orderId', sortOrder: 'asc' }))
          dispatch(setShowSpinner(false))
        } catch (e) {
          console.log(e)
          dispatch(
            setSnackbar({
              type: 'error',
              value: e.message,
            })
          )
          dispatch(setProducts([]))
          setDisplayProducts([])
          dispatch(setShowSpinner(false))
        }
      }
      getProducts()
    } else {
      navigate('/', { replace: true })
    }
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [location.search, dispatch, navigate])

  const handleOnResize = () => {
    setIsMobileScreen(window.innerWidth < 767)
  }

  const handleOnSearch = (e) => {
    setSearchValue(e.target.value)
    const array = [...products]
    const filteredArray = array.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()))
    setDisplayProducts(e.target.value ? filteredArray : products)
  }

  const sort = (displayProducts, item) => {
    const array = [...displayProducts]
    array.sort((x, y) => {
      const a = x[item.sortBy],
        b = y[item.sortBy]
      const comparison = a > b ? 1 : a < b ? -1 : 0
      return item.sortOrder === 'desc' ? comparison * -1 : comparison
    })
    return array
  }

  const handleSortBy = (item) => {
    setSortByValue(item.value)
    const array = sort(displayProducts, item)
    setDisplayProducts(array)
  }

  return (
    !showSpinner && (
      <div className='category-container'>
        <header className='category-header mb20 text-center'>{subCategoryName}</header>
        {products && products.length > 0 && (
          <div className='product-title'>
            <Input
              type='search'
              name='search'
              value={searchValue}
              placeholder='Search by name'
              className='product-search mtb10 mr20'
              handleOnChange={handleOnSearch}
            />
            <Dropdown
              id='sortBy'
              placeholder='Sort By'
              searchText='Sort By'
              filterBy='value'
              value={sortByValue}
              classObject={{ container: 'product-dropdown' }}
              options={sortByItems}
              handleOnChange={handleSortBy}
            />
          </div>
        )}
        {displayProducts && displayProducts.length ? (
          <>
            <div
              className='category-list'
              style={{
                gridTemplateColumns: displayProducts.length === 1 && isMobileScreen ? 'auto' : '',
              }}
            >
              {displayProducts.map((product, index) => (
                <div
                  className='card cursor-pointer'
                  key={product._id}
                  onClick={() => {
                    setShowModal(true)
                    setCurrentIndex(index)
                  }}
                >
                  <img
                    className='card-image'
                    src={product.images[0].image}
                    alt='category'
                    onError={(e) => {
                      e.target.src = logo
                      e.target.style.height = 'auto'
                      e.target.style.objectFit = 'contain'
                      e.target.style.margin = 'auto'
                    }}
                    onLoad={(e) => {
                      e.target.style.background = 'none'
                      e.target.style.height = 'auto'
                    }}
                  />
                  <div className='mt10 mb10'>{product.name}</div>
                  {product.price && <div className='mb10'>&#8377;{product.price}</div>}
                </div>
              ))}
            </div>
          </>
        ) : (
          <h1 className='not-available'>Sorry, no products are available.</h1>
        )}
        {showModal && (
          <Modal
            handleOnClose={() => {
              setShowModal(false)
            }}
          >
            <div className='product-modal-container'>
              {displayProducts[currentIndex].images.length === 1 ? (
                <img
                  className='product-modal-item product-modal-image'
                  src={`${displayProducts[currentIndex].images[0].image}`}
                  alt='category'
                  onError={(e) => {
                    e.target.src = logo
                    e.target.style.paddingBottom = '20px'
                  }}
                  onLoad={(e) => {
                    e.target.style.background = 'none'
                  }}
                />
              ) : (
                <div className='product-modal-item'>
                  <Carousel
                    carouselItems={displayProducts[currentIndex].images.map((image) => ({
                      image: image.image,
                    }))}
                  />
                </div>
              )}
              <div className='product-modal-item'>
                <div>{displayProducts[currentIndex].name}</div>
                {displayProducts[currentIndex].price && <div>&#8377;{displayProducts[currentIndex].price}</div>}
                <br />
                <div
                  className='break-spaces mb20'
                  dangerouslySetInnerHTML={{
                    __html: displayProducts[currentIndex].description,
                  }}
                />
              </div>
            </div>
            <div className='product-modal-button'>
              <Button
                text='Enquire'
                className='btn btn-primary m10 btn-product'
                handleOnClick={() => {
                  dispatch(setProduct(displayProducts[currentIndex].name))
                  navigate('/contact-us')
                }}
              />
            </div>
          </Modal>
        )}
      </div>
    )
  )
}

export default Products
