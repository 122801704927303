import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Header.scss'
import logo from '../../../images/logo.png'

const routes = [
  { to: '/', exact: true, name: 'Home' },
  { to: '/about-us', exact: true, name: 'About Us' },
]

const subRoutes = [
  { to: '/collection?categoryId=5efc9ef96a39ce195c6fbc79', exact: true, name: 'Burhani Jewellers' },
  { to: '/collection?categoryId=5f003c8e6079fc48209dde0b', exact: true, name: 'Maimoon Creations' },
  { to: '/collection?categoryId=5fe98096f3b0bfa51394dfa1', exact: true, name: 'Classic Zone' },
  { to: '/collection?categoryId=5f152ac4d7fb6a3cc897adac', exact: true, name: 'S. M. B. Collections' },
]

const Header = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768)
  const [width, setWidth] = useState(window.innerWidth)

  const navigationItems = useRef(null)
  const dropdown = useRef(null)

  // const setInnerWidth = useCallback(() => {
  //   setWidth(window.innerWidth);
  // }, []);

  const handleOnResize = useCallback(() => {
    if (width !== window.innerWidth) {
      setWidth(window.innerWidth)
      if (window.innerWidth < 768) {
        navigationItems.current.classList.add('show')
      } else {
        navigationItems.current.classList.remove('show')
      }
      setIsMobileScreen(window.innerWidth < 768 ? true : false)
    }
  }, [width])

  useEffect(() => {
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [handleOnResize])

  const toggleSideNavigation = () => {
    navigationItems.current.classList.toggle('show')
  }

  const toggleNavigationDropdown = () => {
    dropdown.current.classList.toggle('show-dropdown')
  }

  const hideSideNavigation = () => {
    navigationItems.current.classList.remove('show')
  }

  const hideNavigationDropdown = () => {
    dropdown.current.classList.remove('show-dropdown')
  }

  return (
    <nav className='nav-container'>
      {isMobileScreen && (
        <div
          className='hamburger-menu'
          onClick={() => {
            toggleSideNavigation()
          }}
        >
          &#9776;
        </div>
      )}
      <div className='title'>
        <Link to='/'>
          <img className='nav-logo' src={logo} alt='maroon' />
        </Link>
      </div>
      <div className='nav-items-container' ref={navigationItems}>
        {routes.map((route) => (
          <NavLink
            key={route.name}
            className={`nav-item ${({ isActive }) => (isActive ? 'active' : '')}`}
            to={route.to}
            onClick={() => {
              isMobileScreen && hideSideNavigation()
            }}
          >
            {route.name}
          </NavLink>
        ))}
        <div className='nav-dropdown'>
          <div className='nav-item--dropdown' onClick={toggleNavigationDropdown}>
            <span className='flex-grow-1'>Collections</span>
            <span className='nav-item-chevron--down'></span>
          </div>
          <div ref={dropdown} className='nav-item--dropdown-content'>
            {subRoutes.map((route) => (
              <NavLink
                key={route.name}
                to={route.to}
                className={`nav-dropdown-item ${({ isActive }) => (isActive ? 'dropdown-active' : '')}`}
                onClick={() => {
                  hideNavigationDropdown()
                  isMobileScreen && hideSideNavigation()
                }}
              >
                {route.name}
              </NavLink>
            ))}
          </div>
        </div>
        <NavLink
          className={`nav-item ${({ isActive }) => (isActive ? 'active' : '')}`}
          to='contact-us'
          onClick={() => {
            isMobileScreen && hideSideNavigation()
          }}
        >
          Contact Us
        </NavLink>
      </div>
    </nav>
  )
}

export default React.memo(Header)
