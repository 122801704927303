import React, { useEffect, useCallback } from "react";
import "./Tooltip.scss";

const Tooltip = (props) => {
  const { text, reference } = props;

  const onMouseEnter = useCallback(() => {
    reference && reference.current.classList.add("tooltip");
  }, [reference]);

  const onMouseLeave = useCallback(() => {
    reference && reference.current.classList.remove("tooltip");
  }, [reference]);

  useEffect(() => {
    const node = reference.current;
    if (node) {
      node.addEventListener("mouseover", onMouseEnter);
      node.addEventListener("mouseout", onMouseLeave);
      return () => {
        node.removeEventListener("mouseover", onMouseEnter);
        node.removeEventListener("mouseout", onMouseLeave);
      };
    }
  }, [reference, onMouseEnter, onMouseLeave]);

  return <div className="tooltip-content">{text}</div>;
};

export default Tooltip;
