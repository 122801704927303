import React, { useState, useEffect, useCallback } from 'react'
import './DataTable.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedItem, setShowSpinner, setSnackbar } from '../../../redux/common'
import Button from '../../../common/components/Button'
import Checkbox from '../../../common/components/Checkbox'
import addSign from '../../../images/add-sign.png'
import eyeIcon from '../../../images/eye-icon.png'
import trashIcon from '../../../images/trash-icon.svg'
import { getRequestAsync, postRequestAsync } from '../../../common/genericAPI'
import Input from '../../../common/components/Input/Input'

const DataTable = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { header, headings, route, apiName } = props

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768)
  const [search, setSearch] = useState('')

  const handleOnResize = () => {
    setIsMobileScreen(window.innerWidth < 768)
  }

  useEffect(() => {
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [])

  const getData = useCallback(async () => {
    try {
      dispatch(setShowSpinner(true))
      const response = await getRequestAsync(apiName)
      setData(response[apiName])
      setTableData(response[apiName])
      dispatch(setShowSpinner(false))
    } catch (e) {
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: "error", value: e.message }))
      console.log(e)
    }
  }, [apiName, dispatch])

  useEffect(() => {
    getData()
  }, [getData])

  const handleOnSearch = e => {
    const filteredArray = tableData.filter(dataItem => {
      let concatenatedString = ''
      Object.keys(headings).forEach(key => (concatenatedString += `${dataItem[key] || ''} `))
      return concatenatedString.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
    })
    setData(filteredArray)
    setSearch(e.target.value)
  }

  const handleOnCheckboxChange = e => {
    e.persist()
    setData(
      data.map(dataItem => {
        if (dataItem._id === e.target.name || e.target.name === 'selectAll') {
          return {
            ...dataItem,
            selected: e.target.checked,
          }
        }
        return dataItem
      })
    )
  }

  const handleOnSelection = (item = {}) => {
    dispatch(setSelectedItem(item))
    navigate(`${route}${item._id ? `?id=${item._id}` : ''}`)
  }

  const handleOnDeleteClick = async () => {
    dispatch(setShowSpinner(true))
    const selectedData = data.filter(dataItem => dataItem.selected)
    let ids = [],
      publicIds = []
    selectedData.forEach(dataItem => {
      ids.push(dataItem._id)
      if(apiName === 'subcategories' || apiName === 'products') {
        publicIds.push(...dataItem.images.map(image => image.publicId))      
      }
    })
    try {
      const requestObject = { ids }
      if(publicIds.length) {
        requestObject.publicIds = publicIds
      }
      const { message } = await postRequestAsync(`${apiName}/expunge`, requestObject)
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: "success", value: message }))
      getData()
    } catch (e) {
      dispatch(setShowSpinner(false))
      dispatch(setSnackbar({ type: "error", value: e.message }))
      console.log(e)
    }
  }

  const selectedOrders = deleteOrder =>
    !data.some(dataItem => (deleteOrder ? dataItem.selected : !dataItem.selected))

  return (
    <div className='data-table-container'>
      <h2 className='m0 ptb20'>{header}</h2>
      <Input
        id='search'
        name='search'
        placeholder='Search'
        type='search'
        value={search}
        handleOnChange={handleOnSearch}
      />
      {data.length ? (
        <div className='table-container'>
          {!isMobileScreen && (
            <div className='table-header'>
              <Checkbox
                name='selectAll'
                handleOnChange={handleOnCheckboxChange}
                checked={selectedOrders()}
              />
              {Object.keys(headings).map(key => (
                <div key={key} className='table-cell'>
                  {headings[key]}
                </div>
              ))}
              <div className='table-cell text-right'>Action</div>
            </div>
          )}
          <div className='table-body'>
            {data.map((row, index) => (
              <div className='table-row-container' key={index}>
                {isMobileScreen && (
                  <div className='table-header'>
                    <div className='table-cell'>Select</div>
                    {Object.keys(headings).map(key => (
                      <div key={key} className='table-cell'>
                        {headings[key]}
                      </div>
                    ))}
                    <div className='table-cell'>Action</div>
                  </div>
                )}
                <div className='table-row' key={index}>
                  <Checkbox
                    name={row['_id']}
                    handleOnChange={handleOnCheckboxChange}
                    checked={row.selected || ''}
                  />
                  {Object.keys(headings).map(
                    key =>
                      headings[key] && (
                        <div className='table-cell' key={key}>
                          {row[key] || '-'}
                        </div>
                      )
                  )}
                  <div className='table-cell text-right action-container'>
                    <img
                      className='mr20'
                      src={eyeIcon}
                      alt='view'
                      onClick={() => {
                        handleOnSelection(row)
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>No data available</div>
      )}
      {JSON.parse(localStorage.getItem('role')) && (
        <Button
          text={<img className='add-sign' src={trashIcon} alt='trash' />}
          className='btn-ghost fixed-logo left'
          handleOnClick={handleOnDeleteClick}
          isDisabled={selectedOrders(true)}
        />
      )}
      <Button
        text={<img className='add-sign' src={addSign} alt='add' />}
        className='btn-ghost fixed-logo'
        handleOnClick={() => {
          handleOnSelection()
        }}
      />
    </div>
  )
}

export default DataTable
