import React, { useState, useEffect, useRef, useCallback } from 'react'
import './productsForm.scss'
import Input from '../../../../common/components/Input/Input'
import Button from '../../../../common/components/Button'
import { regex } from '../../../../utils/regex'
import { postRequestAsync, getRequestAsync, putRequestAsync } from '../../../../common/genericAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Dropdown from '../../../../common/components/Dropdown/Dropdown'
import { setSnackbar, setShowSpinner, setSelectedItem } from '../../../../redux/common'

const Form = () => {
  const { search = '' } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => state.common.selectedItem)

  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [fields, setFields] = useState({
    name: '',
    price: '',
    subcategoryName: '',
    categoryName: '',
    description: '',
    orderId: '',
    image: '',
    secondaryImages: [],
  })
  const [errors, setErrors] = useState({
    subcategoryName: '',
    categoryName: '',
    description: '',
    orderId: '',
    image: '',
    secondaryImages: '',
  })
  const optionalFields = useRef({
    description: true,
    orderId: true,
    price: true,
    secondaryImages: true,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [image, setImage] = useState('')
  const [secondaryImages, setSecondaryImages] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [deselectedImages, setDeselectedImages] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (e) => {
    e.returnValue = 'Changes you made may not be saved.'
  }

  useEffect(() => {
    if (!(selectedItem && Object.keys(selectedItem).length) && search.includes('id')) {
      const getProducts = async () => {
        try {
          dispatch(setShowSpinner(true))
          const response = await getRequestAsync('products', search)
          dispatch(setSelectedItem(response.products[0]))
          dispatch(setShowSpinner(false))
        } catch (e) {
          console.log(e)
          dispatch(setShowSpinner(false))
        }
      }
      getProducts()
    }
  }, [selectedItem, search, dispatch])

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length) {
      setIsUpdate(true)
      setFields({
        name: selectedItem.name,
        price: selectedItem.price || '',
        subcategoryName: selectedItem.subcategoryName,
        categoryName: selectedItem.categoryName,
        description: selectedItem.description,
        orderId: selectedItem.orderId || '',
        secondaryImages: [],
      })
      setImage(selectedItem.images[0].image)
      let secondaryImages = [...selectedItem.images]
      secondaryImages.shift()
      setSecondaryImages(secondaryImages || [])
    }
  }, [selectedItem])

  useEffect(() => {
    if (fields.image) {
      const objectUrl = URL.createObjectURL(fields.image)
      setImage(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [fields.image])

  useEffect(() => {
    if (fields.secondaryImages && fields.secondaryImages.length) {
      let secondaryImages = []
      Array.from(fields.secondaryImages).forEach((imageItem) => {
        secondaryImages.push({ image: URL.createObjectURL(imageItem), name: imageItem.name })
      })
      setSecondaryImages((existingImages) => [...existingImages, ...secondaryImages])
      return () => {
        Array.from(fields.secondaryImages).forEach((imageItem) => {
          URL.revokeObjectURL(imageItem.image)
        })
      }
    }
  }, [fields.secondaryImages])

  const getSubcategories = useCallback(
    async (id) => {
      try {
        dispatch(setShowSpinner(true))
        const subcategoriesResponse = await getRequestAsync('subcategories', id ? `?categoryId=${id}` : '')
        setSubcategories(
          subcategoriesResponse.subcategories.map((subcategory) => ({
            ...subcategory,
            value: subcategory.subcategoryName,
          }))
        )
        dispatch(setShowSpinner(false))
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const getCategoriesAndSubcategories = async () => {
      try {
        dispatch(setShowSpinner(true))
        const categoriesResponse = await getRequestAsync('categories')
        setCategories(
          categoriesResponse.categories.map((category) => ({
            ...category,
            value: category.categoryName,
          }))
        )
        dispatch(setShowSpinner(false))
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'error', value: e.message }))
      }
    }
    getCategoriesAndSubcategories()
  }, [dispatch])

  useEffect(() => {
    if (selectedItem && selectedItem.subcategoryName) {
      getSubcategories()
    }
  }, [selectedItem, getSubcategories])

  const handleOnDropdownChange = (name, selectedItem) => {
    setFields((fields) => ({ ...fields, [name]: selectedItem.value }))
  }

  const handleOnCategoriesChange = async (selectedItem) => {
    setFields((fields) => ({ ...fields, categoryName: selectedItem.value, subcategoryName: '' }))
    getSubcategories(selectedItem._id)
  }

  const handleOnChange = (e) => {
    e.persist()
    const trimmedValue = e.target.value.trim()
    setFields((fields) => ({ ...fields, [e.target.name]: trimmedValue ? e.target.value : trimmedValue }))
  }

  const handleOnNumericValueChange = (e) => {
    e.persist()
    if (regex['numeric'].test(e.target.value)) {
      handleOnChange(e)
    }
  }

  const handleOnImageChange = (e) => {
    e.persist()
    setFields((fields) => ({ ...fields, [e.target.name]: e.target.files[0] }))
  }

  const handleOnSecondaryImageChange = (e) => {
    e.persist()
    setFields((fields) => ({ ...fields, [e.target.name]: e.target.files }))
  }

  const validate = useCallback(() => {
    let error = {},
      isValid = true
    Object.keys(fields).forEach((key) => {
      if (!optionalFields.current[key]) {
        if (regex[key] && !regex[key].test(fields[key])) {
          error[key] = 'Invalid format'
          isValid = false
        }
        if (!fields[key]) {
          error[key] = 'Field is required'
          isValid = false
        }
      }
    })
    setErrors(error)
    return isValid
  }, [fields])

  useEffect(() => {
    setIsDisabled(!validate())
  }, [validate])

  const handleOnDelete = (imageItem) => {
    setSecondaryImages(secondaryImages.filter((secondaryImage) => secondaryImage.image !== imageItem.image))
    setDeselectedImages((deselectedImages) => [...deselectedImages, imageItem.publicId])
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    const functionCalls = {
      insert: postRequestAsync,
      update: putRequestAsync,
    }
    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      if (key !== 'secondaryImages') {
        formData.append(key, fields[key])
      }
    })
    for (let key of Object.keys(fields.secondaryImages)) {
      formData.append('secondaryImages', fields.secondaryImages[key])
    }
    const subcategory = subcategories.find((subcategory) => subcategory.subcategoryName === fields.subcategoryName)
    formData.append('categoryId', subcategory.categoryId)
    formData.append('subcategoryId', subcategory._id)
    if (isUpdate) {
      formData.append('_id', selectedItem._id)
      formData.append('publicId', selectedItem.images[0].publicId)
      for (let key of Object.keys(deselectedImages)) {
        formData.append('deselectedImages', deselectedImages[key])
      }
    }
    setIsSubmitted(true)
    if (validate()) {
      try {
        dispatch(setShowSpinner(true))
        const { message } = await functionCalls[isUpdate ? 'update' : 'insert']('products', formData)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'success', value: message }))
        navigate('/dashboard/products', { replace: true })
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
        dispatch(setSnackbar({ type: 'error', value: e.message }))
      }
    }
  }

  return (
    <div className='form-container'>
      <h2 className='ptb20'>{!isUpdate ? 'Add Product' : 'Update Product'}</h2>
      <form className='form' onSubmit={handleOnSubmit}>
        <Dropdown
          id='category'
          value={fields.categoryName}
          placeholder='Select Category'
          searchText='Search Category'
          filterBy='value'
          options={categories}
          handleOnChange={(selectedItem) => {
            handleOnCategoriesChange(selectedItem)
          }}
          classObject={{ container: 'mb10' }}
          isDisabled={!categories.length}
        />
        <Dropdown
          id='subcategoryName'
          value={fields.subcategoryName}
          placeholder='Select Subcategory'
          searchText='Search Subcategory'
          filterBy='value'
          options={subcategories}
          handleOnChange={(selectedItem) => {
            handleOnDropdownChange('subcategoryName', selectedItem)
          }}
          classObject={{ container: 'mb10' }}
          isDisabled={!subcategories.length}
        />
        <Input
          id='name'
          name='name'
          placeholder='Product Name'
          value={fields.name}
          handleOnChange={handleOnChange}
          error={errors.name}
          isSubmitted={isSubmitted}
        />
        <Input
          id='description'
          name='description'
          placeholder='Description'
          inputType='textarea'
          value={fields.description}
          handleOnChange={handleOnChange}
          error={errors.description}
          isSubmitted={isSubmitted}
        />
        <Input
          id='price'
          name='price'
          placeholder='Price'
          type='tel'
          value={fields.price}
          handleOnChange={handleOnNumericValueChange}
          error={errors.price}
        />
        <Input
          id='orderId'
          name='orderId'
          placeholder='Order ID'
          type='tel'
          value={fields.orderId}
          handleOnChange={handleOnNumericValueChange}
          error={errors.orderId}
        />
        <input type='file' id='image' name='image' accept='image/*' onChange={handleOnImageChange} />
        {image && <img src={image} className='selected-image' alt='file-path' />}
        {isSubmitted && errors.image && <div className='error'>{errors.image}</div>}
        <input
          type='file'
          id='secondaryImages'
          name='secondaryImages'
          className='mt10'
          accept='image/*'
          onChange={handleOnSecondaryImageChange}
          multiple
        />
        {secondaryImages.length > 0 &&
          secondaryImages.map((imageItem, index) => {
            return (
              <div key={index} className='image-container'>
                <img src={imageItem.image} alt='file-path' />
                {selectedItem.images && selectedItem.images.length > 1 && (
                  <Button
                    text={<span>&times;</span>}
                    className='btn-ghost fixed-logo image-close-icon'
                    handleOnClick={() => {
                      handleOnDelete(imageItem)
                    }}
                  />
                )}
              </div>
            )
          })}
        <Button
          text={!isUpdate ? 'Add Product' : 'Update Product'}
          isDisabled={isDisabled}
          className='btn btn-primary mt20'
          type='submit'
        />
      </form>
    </div>
  )
}

export default Form
