import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import facebookLogo from "../../../images/facebook.png";
import instagramLogo from "../../../images/instagram.png";
import pinterestLogo from "../../../images/pinterest.png";
import youTubeLogo from "../../../images/youTube.png";
import linkedInLogo from "../../../images/linkedIn.png";
import { useDispatch } from "react-redux";
import { setShowSpinner } from "../../../redux/common";
import { getRequestAsync } from "../../genericAPI";
import { titleCase } from "../../../utils";

const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getFooterLinks = async () => {
      dispatch(setShowSpinner(true));
      try {
        const response = await getRequestAsync("footer-links");
        setFooterLinks(response);
        dispatch(setShowSpinner(false));
      } catch (e) {
        setFooterLinks([]);
        dispatch(setShowSpinner(false));
      }
    };
    getFooterLinks();
  }, [dispatch]);

  return (
    <footer className="footer-container">
      <div className="footer-links">
        {footerLinks.length > 0 &&
          footerLinks.map((category) => {
            return (
              <div key={category.id} className="footer-links-section">
                <div className="footer-links-title">
                  <Link
                    className="footer-link p0"
                    to={`/collection?categoryId=${category.id}`}
                  >
                    {category.name}:
                  </Link>
                </div>
                <div className="footer-links-description">
                  {category.subcategories.map((subCategory, index) => {
                    return (
                      <Link
                        className="footer-link"
                        to={`/products?id=${subCategory.id}`}
                        key={subCategory.id}
                      >
                        {titleCase(subCategory.name)}
                        {index !== category.subcategories.length - 1 && (
                          <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                        )}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <div className="footer-static-links mt10">
        <Link className="footer-link pl10" to="/">
          Home
        </Link>
        <Link className="footer-link" to="/about-us">
          About Us
        </Link>
        <Link className="footer-link" to="/contact-us">
          Contact Us
        </Link>
        <Link className="footer-link" to="/terms-conditions">
          Terms & Conditions
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy Policy
        </Link>
        <a
          className="footer-link"
          href="https://m.moneycontrol.com/commodity/top-commodities"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get Live Prices
        </a>
      </div>
      <div className="footer-details">
        <p>Follow Us</p>
        <a
          className="social-logo mr10"
          href="https://www.instagram.com/burhanijewellers/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="width-100" src={instagramLogo} alt="instagram" />
        </a>
        <a
          className="social-logo mr10"
          href="https://www.facebook.com/burhani.jeweller"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="width-100" src={facebookLogo} alt="instagram" />
        </a>
        <a
          className="social-logo mr10"
          href="https://www.linkedin.com/company/burhani-jewellers/about/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="width-100" src={linkedInLogo} alt="linkedIn" />
        </a>
        <a
          className="social-logo mr10"
          href="https://www.pinterest.com/burhanijeweller/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="width-100" src={pinterestLogo} alt="pinterest" />
        </a>
        <a
          className="social-logo mr10"
          href="https://www.youtube.com/channel/UCEg_enR7AeEATXkDVbOAjJQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="width-100" src={youTubeLogo} alt="youTube" />
        </a>
      </div>
      <div className="footer-details pb20">
        &copy; {new Date().getFullYear()} Burhani Jewellers. All Rights
        Reserved.
      </div>
    </footer>
  );
};

export default React.memo(Footer);
