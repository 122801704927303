import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsAndConditions = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="terms-and-conditions-container">
      <h1 className="text-center">TERMS AND CONDITIONS</h1>
      <p className="padding-bottom-20">
        <b>Last updated: April 2020</b>
      </p>
      <p>
        These Terms and Conditions (“Terms”) govern the access or use by you, an
        individual of website, products, services (“Services”) made available to
        you by Burhani Jewellers, a company based in Mumbai, India.
      </p>
      <p>
        Your access to these Services is conditioned on your acceptance of and
        compliance with these Terms. These Terms apply to all visitors, users
        and others who access or use the Services.
      </p>
      <p>
        Please read these Terms of Use carefully before using the Platform. By
        using the Platform, you signify your assent and agreement to these Terms
        of Use.
      </p>
      <h1>Information Accuracy</h1>
      <p>
        Our continuous efforts make sure that the website is up to date. Despite
        our efforts, however, information on this site may be not accurate, or
        maybe out of date. The products, description and prices are subject to
        change at any time without notice. We make reasonable efforts to
        accurately display and keep up with time, display all the information
        about the product. However, the actual product may vary and we cannot
        guarantee that it would be same because the computer, the colour
        choices, the screens, may portray the product in a different manner. The
        inclusion of any products or services on this website does not mean that
        they will be available at any time. We reserve the right to bring in new
        products or discontinue any product at any time.
      </p>
      <h1>Intellectual Property</h1>
      <p>
        The Website and all its materials, including, but not limited to its
        HTML code, scripts, text, design, artwork, photographs, videos, audio
        (“Materials”) are provided as a service to you and cannot be used if you
        do not have the copyright for it. No exploitation of any of the
        Materials or the products (online/offline) we provide could be done
        without having a copyright. No Services provided by us could be altered,
        sold, distributed transmitted, displayed, reproduced, published, posted
        on any social media, license without our written consent or without the
        copyright of the Company.
      </p>
      <h1>Terms of Sale</h1>
      <p>
        By placing an order online/offline, or by contacting us, you are
        representing that you are of legal age, representing that all the
        information that you provide is valid and accurate, that you are an
        authorized user of the payment method you would provide, online/offline.
        Prior to our acceptance of an order, we may verify the information you
        have provided us.
      </p>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about these Terms and Conditions, please feel
        free to{" "}
        <Link to="/contact-us">
          <b>Contact Us</b>
        </Link>{" "}
        .
      </p>
    </section>
  );
};

export default TermsAndConditions;
