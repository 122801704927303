import React, { useState, useEffect, useCallback, useRef } from 'react'
import './ContactUs.scss'
import Button from '../../common/components/Button/Button'
import { postRequestAsync } from '../../common/genericAPI'
import Input from '../../common/components/Input'
import { useSelector, useDispatch } from 'react-redux'
import { setSnackbar, setShowSpinner } from '../../redux/common'
import locationPin from '../../images/location-pin.png'
import contactWhatsAppIcon from '../../images/contact-whatsApp.svg'
import contactEmailIcon from '../../images/contact-email.svg'
import contactCallIcon from '../../images/contact-call.svg'
import email from '../../images/email.svg'
import mobile from '../../images/mobile.png'

const regex = {
  name: /^[a-zA-Z ]*$/,
  email: /^[a-zA-Z0-9]+((.|-|\+|_)[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,4})?$/,
}

const ContactUs = () => {
  const product = useSelector((state) => state.productDetails.product)

  const fields = {
    name: '',
    email: '',
    productName: product,
    description: '',
  }

  const dispatch = useDispatch()

  const [formFields, setFormFields] = useState(fields)
  const [formErrors, setFormErrors] = useState(fields)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)

  const optionalFields = useRef({
    productName: true,
  })

  const [showOptions, setShowOptions] = useState({
    productName: true,
    description: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (e) => {
    e.returnValue = 'Changes you made may not be saved.'
  }

  const handleOnChange = (e) => {
    e.persist()
    const trimmedValue = e.target.value.trim()
    setFormFields((formFields) => ({
      ...formFields,
      [e.target.name]: trimmedValue ? e.target.value : trimmedValue,
    }))
  }

  const validate = useCallback(() => {
    let error = {},
      isValid = true

    setIsDisabled(false)
    Object.keys(formFields).forEach((key) => {
      if (!optionalFields.current[key] && showOptions[key]) {
        if (regex[key] && !regex[key].test(formFields[key])) {
          error[key] = 'Invalid format'
          isValid = false
        }
        if (!formFields[key]) {
          error[key] = 'Field is required'
          isValid = false
          setIsDisabled(true)
        }
      }
    })
    setFormErrors(error)
    return isValid
  }, [formFields, showOptions])

  useEffect(() => {
    validate()
  }, [validate])

  const handleOnWhatsAppSubmit = (e) => {
    e.preventDefault()
    const text = `Product: ${formFields.productName}. Description: ${formFields.description}`
    window.location.href = `https://api.whatsapp.com/send?phone=919820381852&text=${text}&source=&data=`
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (validate()) {
      try {
        const fields = { ...formFields }
        Object.keys(fields).forEach((key) => !fields[key] && delete fields[key])
        dispatch(setShowSpinner(true))
        await postRequestAsync('email', fields)
        dispatch(setShowSpinner(false))
        dispatch(
          setSnackbar({
            type: 'success',
            value: 'Form has been submitted successfully',
          })
        )
      } catch (e) {
        dispatch(setShowSpinner(false))
        dispatch(
          setSnackbar({
            type: 'error',
            value: 'Unable to submit form, kindly try again after sometime',
          })
        )
      }
    }
  }

  return (
    <div className='background'>
      <div className='contact-us-container'>
        <h1 className='m0 ptb20'>Contact Us.</h1>
        <div className='contact-us-nav-container'>
          <Button
            className={`contact-us-card ${selectedItem === 0 ? 'active' : ''}`}
            text={<img src={contactWhatsAppIcon} alt='whatsApp' />}
            handleOnClick={() => {
              setShowOptions({ productName: true, description: true })
              setSelectedItem(0)
            }}
          />
          <Button
            className={`contact-us-card ${selectedItem === 1 ? 'active' : ''}`}
            text={<img src={contactEmailIcon} alt='email' />}
            handleOnClick={() => {
              setShowOptions({
                name: true,
                email: true,
                productName: true,
                description: true,
              })
              setSelectedItem(1)
            }}
          />
          <Button
            className={`contact-us-card ${selectedItem === 2 ? 'active' : ''}`}
            text={<img src={contactCallIcon} alt='call' />}
            handleOnClick={() => {
              setSelectedItem(2)
            }}
          />
        </div>
        {selectedItem === 0 && (
          <div className='selected-container'>
            <form className='contact-us-form-container' onSubmit={handleOnWhatsAppSubmit}>
              <Input
                label='Product Name'
                id='productName'
                name='productName'
                placeholder='Enter product name'
                value={formFields.productName}
                handleOnChange={handleOnChange}
                error={formErrors.productName}
                isSubmitted={isSubmitted}
                isDisabled={product}
              />
              <Input
                label='Description *'
                id='description'
                name='description'
                inputType='textarea'
                value={formFields.description}
                handleOnChange={handleOnChange}
                error={formErrors.description}
                isSubmitted={isSubmitted}
              />
              <Button text='Send' className='btn btn-primary form-button' isDisabled={!isSubmitted && isDisabled} />
            </form>
            <div className='information-container'>
              <img src={contactWhatsAppIcon} alt='whatsApp' />
              <h3>WhatsApp Us.</h3>
              <p>Quickest. Smartest.</p>
            </div>
          </div>
        )}
        {selectedItem === 1 && (
          <div className='selected-container'>
            <form className='contact-us-form-container' onSubmit={handleOnSubmit}>
              <Input
                label='Name *'
                id='name'
                name='name'
                placeholder='Enter your name'
                value={formFields.name}
                handleOnChange={handleOnChange}
                error={formErrors.name}
                isSubmitted={isSubmitted}
              />
              <Input
                label='Email *'
                id='email'
                name='email'
                placeholder='Enter your email'
                value={formFields.email}
                handleOnChange={handleOnChange}
                error={formErrors.email}
                isSubmitted={isSubmitted}
              />
              <Input
                label='Product Name'
                id='productName'
                name='productName'
                placeholder='Enter product name'
                value={formFields.productName}
                handleOnChange={handleOnChange}
                error={formErrors.productName}
                isSubmitted={isSubmitted}
                isDisabled={product}
              />
              <Input
                label='Description *'
                id='description'
                name='description'
                inputType='textarea'
                value={formFields.description}
                handleOnChange={handleOnChange}
                error={formErrors.description}
                isSubmitted={isSubmitted}
              />
              <Button text='Email' className='btn btn-primary form-button' isDisabled={!isSubmitted && isDisabled} />
            </form>
            <div className='information-container'>
              <img src={contactEmailIcon} alt='email' />
              <h3>Email us.</h3>
              <p>It's easy.</p>
            </div>
          </div>
        )}
        {selectedItem === 2 && (
          <div className='call-container'>
            <h2>We are available for you, anywhere, everywhere.</h2>
            <div className='information-block-container'>
              <div className='information-block'>
                <img className='information-image' src={locationPin} alt='location-pin' />
                <div>Burhani Jewellers</div>
                <p>
                  Al-Sa'adah Shopping Complex, 1st Floor, A-Wing, Shop No. F38, Opp to Zainabia Hall, Bhendi Bazar,
                  Mumbai - 400003.
                </p>
              </div>
              <div className='information-block'>
                <img className='information-image' src={locationPin} alt='location-pin' />
                <div>Classic Zone</div>
                <p>Shop No. 4, Lathiwala Apartments, Tulsiwadi, Shivdas Chapsi Marg, Mazgaon, Mumbai - 400010</p>
              </div>
              <div className='information-block'>
                <img className='information-image' src={email} alt='email' />
                <a href='mailto:burhanijewellers@gmail.com'>burhanijewellers@gmail.com</a>
              </div>
              <div className='information-block'>
                <img className='information-image' src={mobile} alt='mobile' />
                <div>
                  <a href='tel:+919820381852'>+91-9820381852</a>&nbsp;/&nbsp;
                  <a href='tel:+919820381852'>+91-9354515253</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <iframe
        title='map'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46111.10604634508!2d72.81039893217128!3d18.950252169607566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce3dcabf9d3f%3A0xfea14c0d0c7b46e4!2sBURHANI%20JEWELLERS%2F%20SPECIALIST%20IN%20CUSTOMIZED%20JEWELLERY!5e0!3m2!1sen!2sin!4v1584247814720!5m2!1sen!2sin'
        style={{ border: 0, width: '100%', minHeight: '60vh' }}
        aria-hidden='false'
      ></iframe>
    </div>
  )
}

export default ContactUs
