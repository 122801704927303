import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Carousel from '../../common/components/Carousel/Carousel'
import './Home.scss'

import image0 from '../../images/0.jpg'
import image1 from '../../images/1.jpg'
import image2 from '../../images/2.jpg'
import image3 from '../../images/3.jpg'
import image4 from '../../images/4.jpg'

import collection1 from '../../images/collection-1.jpg'
import collection2 from '../../images/collection-2.jpg'
import collection3 from '../../images/collection-3.jpg'
import collection4 from '../../images/collection-4.jpg'
import { useDispatch } from 'react-redux'
import { setShowSpinner } from '../../redux/common'
import { getRequestAsync } from '../../common/genericAPI'

const carouselItems = [
  { image: image0 },
  { image: image1 },
  { image: image2 },
  { image: image3 },
  { image: image4 },
]

const Home = () => {
  const dispatch = useDispatch()
  const [broadcast, setBroadcast] = useState('')
  useEffect(() => {
    const getBroadcasts = async () => {
      try {
        dispatch(setShowSpinner(true))
        const response = await getRequestAsync('broadcasts', `?active=${true}`)
        setBroadcast(response.broadcasts.length > 0 && response.broadcasts[0].description)
        dispatch(setShowSpinner(false))
      } catch (e) {
        console.log(e)
        dispatch(setShowSpinner(false))
      }
    }
    getBroadcasts()
  }, [dispatch])
  return (
    <React.Fragment>
      {broadcast && <div className='marquee'><span>{broadcast}</span></div>}
      <Carousel carouselItems={carouselItems} />
      <div className='collection-container'>
        <div className='collection-container--left'>
          <Link className='collection-item' to='/collection?categoryId=5efc9ef96a39ce195c6fbc79'>
            <img className='' src={collection1} alt='collection-1' />
            <div className='collection-shine'></div>
            <div className='collection-title'>Burhani Jewellers</div>
          </Link>
          <Link className='collection-item' to='/collection?categoryId=5fe98096f3b0bfa51394dfa1'>
            <img className='' src={collection2} alt='collection-2' />
            <div className='collection-shine'></div>
            <div className='collection-title'>Classic Zone</div>
          </Link>
        </div>
        <div className='collection-container--right'>
          <Link className='collection-item' to='/collection?categoryId=5f152ac4d7fb6a3cc897adac'>
            <img className='' src={collection4} alt='collection-4' />
            <div className='collection-shine'></div>
            <div className='collection-title collection-title--inverse'>S. M. B. Collections</div>
          </Link>
          <Link className='collection-item' to='/collection?categoryId=5f003c8e6079fc48209dde0b'>
            <img className='' src={collection3} alt='collection-3' />
            <div className='collection-shine'></div>
            <div className='collection-title'>Maimoon Creations</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
